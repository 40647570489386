<template>
    <div class="pt-3">
        <div v-for="transaction in transactions"  class="transaction-list" :key="transaction.reference_no" @click="openTransaction(transaction)">
            <div class="font-weight-bold">
                {{ dateUi(transaction.datetime, (transaction.type == 'PAYMENT' || transaction.type == 'UTILITY_PAYMENT') ? 'YYYY-MM-DD HH:mm:ss' : 'YYYY-MM-DD') }}
                <span class="float-right">
                    {{ money(Math.abs(transaction.amount)) }}
                    <span class="font-weight-bold" :class="{'text-danger': transaction.plus_minus == '+', 'text-success': transaction.plus_minus == '-'}">{{ transaction.plus_minus }}</span>
                </span>
            </div>
            <div class="doc-name text-uppercase">{{ lang(transaction.type_ui) }}</div>
            <div>{{ transaction.branch_name }}</div>
            <div>{{ transaction.location_name }}</div>
            <div>{{ transaction.owner_name }}</div>
            <div class="">
                {{ transaction.payment_method }} <span v-if="transaction.customer_phone_no">| {{ transaction.customer_phone_no }}</span>
            </div>
            <div class="font-weigth-bold">{{ transaction.reference_no }}</div>
            <hr>
        </div>
        <div v-if="state.loading || state.loading_next" class="text-center">
            Loading..
        </div>
    </div>
</template>

<script>

export default {
    data() {
        return {
            transactions: [],
            next_page: '',
            current_page: '',
        }
    },

    methods: {
        getTransaction() {
            this.$set(this.state, 'loading', true)
            axios.post(this.GLOBAL.API_BASE_URL +"transaction/list")
                .then(response => {
                    this.transactions = response.data.data
                    this.next_page = response.data.links.next
                    this.current_page = response.data.meta.current_page
                    this.getTransactionNextPage()
                })
                .catch(error=>defaultErrorManagement(error.response)) //to catch the errors if any
                .finally(() => this.$set(this.state, 'loading', false))
        },

        getTransactionNextPage() {
            let bottomOfWindow = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop) + window.innerHeight + 300 >= document.documentElement.offsetHeight

            if(this.next_page && bottomOfWindow) {
                this.$set(this.state, 'loading_next', true)
                axios.post(this.GLOBAL.API_BASE_URL +"transaction/list?page="+ (parseFloat(this.current_page) + 1))
                    .then(response => {
                        this.transactions.push.apply(this.transactions, response.data.data)
                        this.next_page = response.data.links.next
                        this.current_page = response.data.meta.current_page

                        this.getTransactionNextPage()
                    })
                    .catch(error=>defaultErrorManagement(error.response))
                    .finally(() => this.$set(this.state, 'loading_next', false))

                this.next_page = ''
            }
        },

        openTransaction(transaction) {
            if(transaction.type == 'PAYMENT') {
                this.$router.push({ name: 'receipt', params: {resource_id: transaction.type_id, transaction: transaction} })
            }
            else if(transaction.type == 'INVOICE') {
                this.$router.push({ name: 'invoice', params: {resource_id: transaction.type_id, transaction: transaction} })
            }
            else if(transaction.type == 'DEBIT_NOTE') {
                this.$router.push({ name: 'debit-note', params: {resource_id: transaction.type_id, transaction: transaction} })
            }
            else if(transaction.type == 'CREDIT_NOTE') {
                this.$router.push({ name: 'credit-note', params: {resource_id: transaction.type_id, transaction: transaction} })
            }
            else if(transaction.type == 'UTILITY_PAYMENT') {
                this.$router.push({ name: 'utility-payment-slip', params: {resource_type: transaction.utility_type, resource_id: transaction.type_id, transaction: transaction} })
            }
        }
    },

    created() {
        this.getTransaction()
    },

    mounted() {
        addEventListener("scroll", this.getTransactionNextPage)
    },

    beforeDestroy() {
        removeEventListener("scroll", this.getTransactionNextPage)
    },

    beforeRouteLeave(to, from, next) {
        if(to.name == 'auth' || to.name == 'bills.payment-status-gopay' || to.name == 'bills.payment-status-fpx') {
            this.closeApp()
        } else {
            next()
        }
    }
}
</script>

<style lang="scss" scoped>
.transaction-list {
    line-height: 1.4rem;
}
</style>