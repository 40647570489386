import Vue from 'vue'
import VueRouter from 'vue-router'
import Auth from '../Auth.vue'
import Home from '../Home.vue'
import Bills from '../views/Bills.vue'
import Utilities from '../views/Utilities.vue'
import Menu from '../views/menu/Index.vue'
import Einvoice from '../views/menu/Einvoice.vue'
import EinvoiceShow from '../views/menu/EinvoiceShow.vue'
import FavoriteUtilities from '../views/menu/FavoriteUtilities.vue'
import Transaction from '../views/Transaction.vue'
import PaymentList from '../views/PaymentList.vue'
import PaymentSingle from '../views/PaymentSingle.vue'
import BillDetails from '../views/BillDetails.vue'
import PaymentMethod from '../views/PaymentMethod.vue'
import PaymentFpx from '../views/PaymentFpx.vue'
import PaymentGopay from '../views/PaymentGopay.vue'
import PaymentStatusFpx from '../views/PaymentStatusFpx.vue'
import PaymentStatusGopay from '../views/PaymentStatusGopay.vue'
import Receipt from '../views/Receipt.vue'
import Invoice from '../views/Invoice.vue'
import DebitNote from '../views/DebitNote.vue'
import CreditNote from '../views/CreditNote.vue'
import UtilityPaymentSlip from '../views/UtilityPaymentSlip.vue'
import DigitalCard from '../views/DigitalCard/Index.vue'
import CommitteeProfile from '../views/DigitalCard/CommitteeProfile.vue'
import Business from '../views/DigitalCard/Business.vue'
import UtilityDetails from '../views/UtilityDetails.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'auth',
        component: Auth,
        props: true
    },
    {
      path: '/',
      component: Home,
      children: [
          {
            path: '/bills',
            name: 'bills',
            component: Bills
          },
          {
            path: '/utilities',
            name: 'utilities',
            component: Utilities
          },
          {
            path: '/transaction',
            name: 'transaction',
            component: Transaction
          },
      ],
      meta: { requiresAuth: true }
    },
    {
        path: '/bills/details',
        name: 'bills.details',
        component: BillDetails,
        props: true,
        meta: { requiresAuth: true }
    },
    {
        path: '/bills/payment-list',
        name: 'bills.payment-list',
        component: PaymentList,
        meta: { requiresAuth: true }
    },
    {
        path: '/bills/payment-single',
        name: 'bills.payment-single',
        component: PaymentSingle,
        props: true,
        meta: { requiresAuth: true }
    },
    {
        path: '/bills/payment-method',
        name: 'bills.payment-method',
        component: PaymentMethod,
        props: true,
        meta: { requiresAuth: true }
    },
    {
        path: '/bills/payment-status/fpx/:transaction_id',
        name: 'bills.payment-status-fpx',
        component: PaymentStatusFpx,
        props: true,
        meta: { requiresAuth: true }
    },
    {
        path: '/bills/payment-status/gopay/:transaction_id',
        name: 'bills.payment-status-gopay',
        component: PaymentStatusGopay,
        props: true,
        meta: { requiresAuth: true }
    },
    {
      path: '/receipt/:resource_id',
      name: 'receipt',
      component: Receipt,
      props: true,
      meta: { requiresAuth: true }
    },
    {
      path: '/invoice/:resource_id',
      name: 'invoice',
      component: Invoice,
      props: true,
      meta: { requiresAuth: true }
    },
    {
      path: '/debit-note/:resource_id',
      name: 'debit-note',
      component: DebitNote,
      props: true,
      meta: { requiresAuth: true }
    },
    {
      path: '/credit-note/:resource_id',
      name: 'credit-note',
      component: CreditNote,
      props: true,
      meta: { requiresAuth: true }
    },
    {
      path: '/utility-payment-slip/:resource_type/:resource_id',
      name: 'utility-payment-slip',
      component: UtilityPaymentSlip,
      props: true,
      meta: { requiresAuth: true }
    },

    {
      path: '/utilities/details',
      name: 'utilities.details',
      component: UtilityDetails,
      props: true,
      meta: { requiresAuth: true }
    },

    {
      path: '/menu',
      name: 'menu',
      component: Menu,
      meta: { requiresAuth: true }
    },
    {
      path: '/menu/einvoice',
      name: 'menu.einvoice',
      component: Einvoice,
      meta: { requiresAuth: true }
    },
    {
      path: '/menu/einvoice/:resource_id',
      name: 'menu.einvoice.show',
      component: EinvoiceShow,
      props: true,
      meta: { requiresAuth: true }
    },
    {
      path: '/menu/favorite-utilities',
      name: 'menu.favorite-utilities',
      component: FavoriteUtilities,
      meta: { requiresAuth: true }
    },
    {
        path: '/about',
        name: 'about',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    },
    
    {
        path: '/digital-card',
        name: 'digital-card',
        component: DigitalCard,
        props: true,
    },
    {
        path: '/digital-card/:merchant_member_id/committee-profile',
        name: 'digital-card.committee-profile',
        component: CommitteeProfile,
        props: true,
    },
    {
        path: '/digital-card/:merchant_member_id/business',
        name: 'digital-card.business',
        component: Business,
        props: true,
    },

    {
        path: '/main-gonet',
        beforeEnter() {location.href = process.env.VUE_APP_MAIN_GONET_URL}
    }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
