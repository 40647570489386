import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'

import './sass/app.scss'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-regular-svg-icons'
import { faChevronLeft, faSpinner, faPlusCircle, faShareAlt, faChevronCircleDown, faCaretDown, faReply, faPaperclip, faTrashAlt, faBars, faFileAlt, faHeart, faTrash, faSitemap, faCaretUp, faHandshake } from '@fortawesome/free-solid-svg-icons'
library.add(faChevronLeft, faSpinner, faPlusCircle, faCheckCircle, faTimesCircle, faShareAlt, faChevronCircleDown, faCaretDown, faReply, faPaperclip, faTrashAlt, faBars, faFileAlt, faHeart, faTrash, faSitemap, faCaretUp, faHandshake)

// window.Popper = require('popper.js').default;
require('bootstrap');
window.bsCustomFileInput = require('bs-custom-file-input')
window.axios = require('axios')
window.moment = require('moment')
window.Swal = require('sweetalert2')
window._ = require('lodash')
window.$ = window.jQuery = require('jquery');

Vue.config.productionTip = false

axios.defaults.headers.common['Authorization'] = 'Bearer '+ localStorage.token;

Vue.component('secondary-header', require('./layouts/SecondaryHeader.vue').default);
Vue.component('main-footer', require('./layouts/MainFooter.vue').default);
Vue.component('float-bottom', require('./components/FloatBottom.vue').default);
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('loader', require('./components/Loader.vue').default);

window.isAuth = function() {
    return !!localStorage.token
}

window.fireLoader = function(text = 'Waiting...') {
    Swal.fire({
        text: text,
        showConfirmButton: false,
        onBeforeOpen: () => Swal.showLoading()
    })
    // stop loader with Swal.close()
}

window.addError = function(key, value) {
    $('[name="'+key+'"]').addClass('is-invalid').after('<div class="invalid-feedback">'+ value +'</div>')
},

window.removeError = function(field) {
    if(field)
        $('[name="'+ field +'"]').removeClass('is-invalid').next('.invalid-feedback').remove();
    else
        $('.is-invalid').removeClass('is-invalid').next('.invalid-feedback').remove();
}

window.defaultErrorManagement = function(error) {
    if(!error) {
        Swal.fire({
            type: 'error',
            text: 'Something went wrong. Please try again',
            timer: 5000
        })
    }
    else if(error.status == 401) {
        Swal.fire({
            type: 'error',
            text: 'Session expired. Please login again',
            timer: 5000,
            onClose: () => {
                localStorage.clear();
                window.location.replace(process.env.VUE_APP_MAIN_GONET_URL);
            }
        })
    }
    else if(error.status == 403) {
        Swal.fire({
            type: 'error',
            text: error.data.message,
            timer: 10000,
            onClose: () => {
                router.go(-1);
            }
        })
    }
    else if(error.status == 422) {
        Swal.fire({
            type: 'error',
            text: error.data.message,
            timer: 5000,
        })

        removeError()

        $.each(error.data.errors, (key, value) => {
            addError(key, value[0])
        })

        $('.form-control.is-invalid:first').focus()

    } else {
        Swal.fire({
            type: 'error',
            text: 'Something went wrong. Please try again ('+ error.status + ')',
            timer: 5000
        })
    }
}

window.toFormData =  function(obj, form, namespace) {
    let fd = form || new FormData();
    let formKey;
  
    for(let property in obj) {
        if(obj.hasOwnProperty(property)) {
            if (namespace) {
                formKey = namespace + '[' + property + ']';
            } else {
                formKey = property;
            }

            // if the property is an object, but not a File, use recursivity.
            if (obj[property] instanceof Date) {
                fd.append(formKey, obj[property].toISOString());
            }
            else if (typeof obj[property] === 'object' && !(obj[property] instanceof File)) {
                toFormData(obj[property], fd, formKey);
            } else { // if it's a string or a File object
                fd.append(formKey, obj[property]);
            }
        }
    }
  
    return fd;
}

Vue.mixin({
    data() {
        return {
            state: {
                loading: false
            },

            isAuth: isAuth(),

            customer_support_no: '016-8300065 / 016-8300109',

            GLOBAL: {
                API_BASE_URL: process.env.VUE_APP_API_BASE_URL,
                MAIN_GONET_URL: process.env.VUE_APP_MAIN_GONET_URL,
                MAIN_GONET_BACKEND_URL: process.env.VUE_APP_MAIN_GONET_BACKEND_URL,

                GONET_ADMIN_URL: process.env.VUE_APP_GONET_ADMIN_URL,

                BUILD_VERSION: '0.6.37',

                IS_DEV: (process.env.VUE_APP_API_BASE_URL).startsWith("https://dev.gonet.com.my") || (process.env.VUE_APP_API_BASE_URL).startsWith("http://host.docker")
            }
        }
    },

    methods: {
        lang(message, var_arr) {
            if(var_arr) {
                for (var key in var_arr) {
                    if (var_arr.hasOwnProperty(key)) {
                        message = message.replace(':'+key, var_arr[key])
                    }
                }
            }
            return message
        },

        money(value, unit = true) {
            if(value == undefined) value = 0
            var return_value = String(value)
            if(return_value) {
                return_value = return_value.replace(',','') //remove comma(,)
                return_value = return_value.replace(' ','') //remove space( )
            } else {
                return_value = 0
            }
            return_value = _.round(this.moneyRounding(return_value), 2)
            return (return_value < 0 ? '- ' : '') + (unit ? 'RM ' : '') + Math.abs(return_value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
        },

        moneyRounding(number) {
            return Math.round((Math.round(number * 1e12) / 1e12) * 100) / 100
        },

        dateUi(date, format) {
            return moment(date).format(format ? format : 'DD-MM-YYYY')
        },

        goBack(range) {
            router.go(range)
        },

        localStorageGetItem(key) {
            return localStorage.getItem(key)
        },

        isNative() {
            return window.ReactNativeWebView;
        },

        closeApp(page = "gopay") {
            if (this.isNative()) {
                window.ReactNativeWebView.postMessage("WINDOW_CLOSED");
            } else {
                window.location.href = this.GLOBAL.MAIN_GONET_URL + page;
            }
        },
    }
})

new Vue({
    router,
    render: h => h(App),
    created() {
        // this.updateLastLogin()

        // auth for first load
        if (router.currentRoute.matched.some(record => record.meta.requiresAuth)) {
            // this route requires auth, check if logged in
            // if not, redirect to login page.
            if(!this.isAuth) {
                router.push('/login?'+ router.currentRoute.fullPath)
            }
        }
        else if(router.currentRoute.matched.some(record => record.meta.requiresGuest)) {
            // guest only
            // if logged in redirect to home
            if(isAuth()) {
                router.push('/home')
            }
        }
    },

    mounted() {
        $('<span>')
            .addClass('build-version')
            .html(this.GLOBAL.BUILD_VERSION)
            .appendTo('body')
    }
}).$mount('#app')


router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        // this route requires auth, check if logged in
        // if not, redirect to login page.
        if(!isAuth()) {
            next({
                name: 'login',
                query: { redirect: to.fullPath }
            })
        } else {
            next()
        }
    }
    else if(to.matched.some(record => record.meta.requiresGuest)) {
        // guest only
        // if logged in redirect to home
        if(isAuth()) {
            next({name: 'home'})
        } else {
            next()
        }
    } else {
        next() // make sure to always call next()!
    }
})
