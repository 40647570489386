<template>
    <div class="single-header">
        <secondary-header title="GoBills" backRoute="default"></secondary-header>

        <main>
            <div class="container select-bills pb-5 mb-3">
                <div class="row justify-content-center">
                    <div class="col-md-10 content">
                        <div class="header">
                            <div class="container">
                                <div class="row justify-content-center">
                                    <div class="text-right mb-1 pt-2 px-0 col-md-10">
                                        {{ lang('Credit Balance') }}: {{ money(wallet_details.wallet_balance) }}
                                        <a href="#" @click.prevent="closeApp('gopay/reload/main')" class="text-info">
                                            <font-awesome-icon icon="plus-circle" size="lg"></font-awesome-icon>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="text-center pt-4 mb-5">
                            <img :src="latest_utility.logo_url" alt="" height="100">
                            <div class="mt-2"><b>{{ latest_utility.name }}</b></div>
                        </div>

                        <div v-if="validation_data.status == 'SUCCESS'">
                            <table width="100%" class="mb-3">
                                <tr>
                                    <td>{{ lang('Transaction Type') }}</td>
                                    <td width="1%" class="px-1">:</td>
                                    <td width="50%">{{ lang(latest_utility.transaction_type) }}</td>
                                </tr>
                                <tr>
                                    <td>{{ lang('Requisite') }}</td>
                                    <td width="1%" class="px-1">:</td>
                                    <td>{{ account_no }}</td>
                                </tr>
                                <template v-if="validation_data.details">
                                    <tr v-for="(detail, i) in validation_data.details" :key="i">
                                        <td>{{ lang(detail.label) }}</td>
                                        <td width="1%" class="px-1">:</td>
                                        <td>{{ detail.value }}</td>
                                    </tr>
                                </template>
                            </table>

                            <div>{{ lang('Amount to pay') }}:</div>
                            <div>
                                <div v-if="validation_data.amount_selections && Array.isArray(validation_data.amount_selections)">
                                    <select v-model="inputs.amount_selection_to_pay" name="amount_selection_to_pay" class="form-control">
                                        <option value="">{{ lang('-- select --') }}</option>
                                        <option v-for="amount in validation_data.amount_selections" :value="amount.key" :key="amount.key">{{ amount.label }}</option>
                                    </select>
                                    <div class="mt-1">
                                        <input type="text" :value="money(inputs.amount_to_pay)" class="form-control" readonly>
                                    </div>
                                </div>

                                <select v-else-if="latest_utility.amount && latest_utility.amount.type == 'SELECTION'" v-model="inputs.amount_to_pay" name="amount_to_pay" class="form-control">
                                    <option value="">{{ lang('-- select --') }}</option>
                                    <option v-for="amount in latest_utility.amount.selection" :value="amount" :key="amount">{{ money(amount) }}</option>
                                </select>

                                <input v-else-if="latest_utility.amount && latest_utility.amount.type == 'FIXED_FROM_VALIDATION'" type="number" v-model="inputs.amount_to_pay" name="amount_to_pay" class="form-control" :class="{'is-invalid': !is_valid_amount_rules}" autocomplete="false" readonly>
                                
                                <input v-else type="number" v-model="inputs.amount_to_pay" name="amount_to_pay" class="form-control" :class="{'is-invalid': !is_valid_amount_rules}" autocomplete="false">
                            </div>

                            <div v-if="latest_utility.amount && latest_utility.amount.hints" class="hints">
                                <div v-for="hint in latest_utility.amount.hints" :key="hint">{{ hint }}</div>
                            </div>

                            <div class="mt-3">
                                <div class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input" id="favorite" v-model="inputs.is_favorite">
                                    <label class="custom-control-label" for="favorite">{{ lang('Utilities Favorite') }}</label>
                                </div>
                            </div>
                            <div class="mt-3">
                                <input type="text" v-model="inputs.favorite_name" name="favorite_name" class="form-control account-no" autocomplete="false" :placeholder="lang('Favorite Name')">
                            </div>
                        </div>
                        <div v-else-if="latest_utility.requisite">
                            <div>
                                <div>{{ latest_utility.requisite.label }}:</div>
                                <div>
                                    <input :type="latest_utility.requisite.type == 'NUMERIC' ? 'number' : 'text'" v-model="account_no" name="account_no" :class="{'is-invalid': !is_valid_account_no_rules}" class="form-control account-no" autocomplete="false">
                                </div>
                                <div v-if="latest_utility.requisite.hints" class="hints">
                                    <div v-for="hint in latest_utility.requisite.hints" :key="hint">{{ hint }}</div>
                                </div>
                            </div>

                            <div class="mt-3">
                                By clicking on the "Proceed" button, you hereby agree with merchant's Terms & Conditions.
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="mt-5" hidden>
                <div class="">
                    {{ lang('Pay Using') }} :
                </div>
                <form id="gonetpay-form" class="content pt-4" :action="GLOBAL.MAIN_GONET_BACKEND_URL + '/ewallet-payment'" method="POST">
                    <input v-for="(value, field) in payment_details.gopay" type="hidden" :name="field" :value="value" :key="field + value">
                    <div class="">
                        <button type="submit" class="btn btn-primary btn-block">{{ lang('GoNetPay') }}</button>
                    </div>
                </form>
                <form id="fpx-form" class="content pt-4" :action="GLOBAL.GONET_ADMIN_URL + '/payments/fpx/checkout'" method="POST">
                    <input v-for="(value, field) in payment_details.fpx" type="hidden" :name="field" :value="value" :key="field + value">
                    <div class="">
                        <button type="submit" class="btn btn-primary btn-block">{{ lang('Online Banking') }} / {{ lang('Credit Card') }}</button>
                    </div>
                </form>
            </div>


            <float-bottom>
                <a v-if="validation_data.status == 'SUCCESS'" href="#" @click.prevent="payNow()">
                    <button type="button" class="btn btn-primary btn-block button-payment" :disabled="state.loading_wallet || !can_pay">
                        <span
                            v-if="validation_data.duplicate && validation_data.duplicate.status == 'DUPLICATE'"
                            class="insifficient-balance"
                            @click.prevent=""
                        >
                            {{ lang(validation_data.duplicate.message) }}
                        </span>
                        <span
                            v-if="inputs.amount_to_pay > wallet_details.wallet_balance"
                            class="insifficient-balance"
                            @click.prevent=""
                        >
                            {{ lang('Insufficient balance, please reload.') }}
                        </span>
                        {{ lang('Pay Now') }}
                    </button>
                </a>
                <a v-else href="#" @click.prevent="validateAccountNo()">
                    <button type="button" class="btn btn-info btn-block" :disabled="state.loading_wallet || !can_proceed">{{ lang('Proceed') }}</button>
                </a>
            </float-bottom>
        </main>

        <loader v-if="state.loading_validate_payment || state.loading_payment_details || state.loading_payment_details_ready_to_pay" />
    </div>
</template>

<script>

export default {
    props: ['utility'],

    data() {
        return {
            wallet_details: {},
            latest_utility: this.utility,
            validation_data: {},

            account_no: this.utility.requisite,
            inputs: {
                amount_selection_to_pay: '',
                amount_to_pay: '',
            },

            payment_details: {},
        }
    },

    watch: {
        'inputs.amount_selection_to_pay': function(newVal) {
            var selected = this.validation_data.amount_selections.find(x => x.key == this.inputs.amount_selection_to_pay)
            if(selected) {
                this.inputs.amount_to_pay = selected.amount
            } else {
                this.inputs.amount_to_pay = ''
            }
        },

        'latest_utility.is_maintenance': function(newVal) {
            Swal.fire({
                type: 'error',
                text: this.lang('Service under maintenance. Please try again later'),
                timer: 10000,
                onClose: () => {
                    this.$router.go(-1);
                }
            })
        }
    },

    computed: {

        is_valid_account_no_rules: function() {
            var is_valid = true;
            if(this.account_no) {
                if(this.latest_utility.requisite.char) {
                    if(Array.isArray(this.latest_utility.requisite.char)) {
                        is_valid = is_valid && this.latest_utility.requisite.char.includes(this.account_no.length)
                    } else {
                        is_valid = is_valid && this.account_no.length == this.latest_utility.requisite.char
                    }
                }
                if(this.latest_utility.requisite.min_char) {
                    is_valid = is_valid && this.account_no.length >= this.latest_utility.requisite.min_char
                }
                if(this.latest_utility.requisite.max_char) {
                    is_valid = is_valid && this.account_no.length <= this.latest_utility.requisite.max_char
                }
                
                if(this.latest_utility.requisite.type == "NUMERIC") {
                    is_valid = is_valid && !isNaN(this.account_no) && !isNaN(parseFloat(this.account_no))
                }
                else if(this.latest_utility.requisite.type == "PHONE_MY") {
                    is_valid = is_valid && [10, 11].includes(this.account_no.length) && !isNaN(this.account_no) && this.account_no.startsWith("01");
                }
            }
            return is_valid
        },

        can_proceed: function() {
            return this.account_no && this.is_valid_account_no_rules
        },

        is_valid_amount_rules: function() {
            var is_valid = true;
            if(this.inputs.amount_to_pay) {
                if(this.latest_utility.amount.type == 'SELECTION') {
                    is_valid = is_valid && this.latest_utility.amount.selection.includes(this.inputs.amount_to_pay)
                }
                else if(this.latest_utility.amount.type == 'INPUT') {
                    if(this.latest_utility.amount.min) {
                        is_valid = is_valid && this.inputs.amount_to_pay >= this.latest_utility.amount.min
                    }
                    if(this.latest_utility.amount.max) {
                        is_valid = is_valid && this.inputs.amount_to_pay <= this.latest_utility.amount.max
                    }
                    if(this.latest_utility.amount.decimal === false) {
                        is_valid = is_valid && Number.isInteger(Number(this.inputs.amount_to_pay))
                    }
                }
            }
            return is_valid
        },

        can_pay: function() {
            return this.inputs.amount_to_pay > 0
                && this.inputs.amount_to_pay <= this.wallet_details.wallet_balance
                && this.is_valid_amount_rules
                && (this.validation_data.duplicate == false || this.validation_data.duplicate.status != 'DUPLICATE')
        },
    },

    methods: {
        getResource() {
            this.$set(this.state, 'loading', true)
            axios.get(this.GLOBAL.API_BASE_URL +"utilities/"+ this.utility.type +"/"+ this.utility.id)
                .then(response => {
                    this.latest_utility = response.data
                })
                .catch(error=>defaultErrorManagement(error.response)) //to catch the errors if any
                .finally(() => this.$set(this.state, 'loading', false))
        },

        getCustomerWalletDetails() {
            this.$set(this.state, 'loading_wallet', true)
            axios.get(this.GLOBAL.API_BASE_URL +"customer/wallet-details")
                .then((response) => {
                    this.wallet_details = response.data;
                })
                .finally(() => this.$set(this.state, 'loading_wallet', false));
        },

        validateAccountNo() {
            if(this.wallet_details.has_pin) {
                this.$set(this.state, 'loading_validate_payment', true)
                axios.post(this.GLOBAL.API_BASE_URL +"utilities/"+ this.utility.type +"/"+ this.utility.id +"/details", {
                        account_no: this.account_no
                    })
                    .then((response) => {
                        this.validation_data = response.data.validation_data
                        if(response.data.favorite_name) {
                            this.$set(this.inputs, 'is_favorite', true)
                            this.$set(this.inputs, 'favorite_name', response.data.favorite_name)
                        }
                        if(response.data.validation_data.fixed_amount) {
                            this.$set(this.inputs, 'amount_to_pay', response.data.validation_data.fixed_amount) 
                        }
                        if(this.validation_data.status != 'SUCCESS') {
                            Swal.fire({
                                text: this.lang(this.validation_data.message),
                                timer: 5000,
                            })
                        }
                    })
                    .catch(error=>defaultErrorManagement(error.response))
                    .finally(() => this.$set(this.state, 'loading_validate_payment', false));
            } else {
                Swal.fire({
                    text: this.lang('Please set your Transaction Pin before payment'),
                    timer: 5000,
                    onClose: () => {
                        this.closeApp('gopay/transaction-pin-setup')
                    }
                })
            }
        },

        payNow() {
            if(this.wallet_details.has_pin) {
                if(this.can_pay) {
                    this.$set(this.state, 'loading_payment_details', true)
                    axios.post(this.GLOBAL.API_BASE_URL +"utilities/"+ this.utility.type +"/"+ this.utility.id +"/payment-details", {
                            ...this.inputs,
                            ...{
                                uuid: this.validation_data.uuid,
                                account_no: this.account_no
                            }
                        })
                        .then((response) => {
                            this.payment_details = response.data

                            this.$set(this.state, 'loading_payment_details_ready_to_pay', true)
                            setTimeout(function() {
                                $('#gonetpay-form').submit()
                            }, 1000)
                        })
                        .catch(error=>defaultErrorManagement(error.response))
                        .finally(() => this.$set(this.state, 'loading_payment_details', false));
                }
            } else {
                Swal.fire({
                    text: this.lang('Please set your Transaction Pin before payment'),
                    timer: 5000,
                    onClose: () => {
                        this.closeApp('gopay/transaction-pin-setup')
                    }
                })
            }
        },
    },

    beforeRouteEnter (to, from, next) {
        if(to.params.utility == undefined) {
            next({name: 'utilities', replace: true})
        } else {
            next()
        }
    },

    created() {
        if(this.account_no) {
            this.wallet_details.has_pin = true
            this.validateAccountNo()
        }
        this.getResource()
        this.getCustomerWalletDetails()
    },
}
</script>

<style lang="scss" scoped>
    .account-no {
        
    }

    .hints {
        color: grey;
    }
</style>